export const FEATURE_FLAG_COOKIE_NAME = 'x-flag'
export const FEATURE_FLAG_HEADER_NAME = 'X-Flag'

export const flags = {
  FLAG_FOR_TESTING: 'pga-com-flag-for-testing',
  FLAG_COACHES_SEARCH_PRIORITY: 'pga-com-coaches-search-priority',
  FLAG_MARKETING_OPT_IN_EXP: 'pga-com-exp-marketing-opt-in',
  FLAG_AGE_SPECIFIC_GROUP: 'pga-com-age-specific-group-event',
  FLAG_MARKETING_OPT_IN_WAITLIST: 'pga-com-marketing-opt-in-waitlist',
  FLAG_OPTIMIZED_COACHES_SEARCH_PAGE: 'pga-com-optimized-coaches-search-page',
}
